export const Provider = {
  Stripe: 'stripe',
  Braintree: 'braintree',
  ChargebeeV1: 'chargebee_v1',
  ChargebeeV2: 'chargebee_v2',
  PaddleClassic: 'paddle_classic',
  PaddleBilling: 'paddle_billing',
  Maxio: 'maxio',
  Other: 'other',
} as const;

export type ProviderName = (typeof Provider)[keyof typeof Provider];
