import { ProviderFeatures } from './provider-features';

export const paddleClassicProviderFeatures: ProviderFeatures = {
  actions: {
    cancel: {
      enabled: true,
      schedules: {
        immediate: true,
        'end-of-period': false,
      },
      unpauseOnCancel: false,
    },
    pause: {
      enabled: false,
      extendPeriodEnd: false,
      startDates: {
        'period-end': false,
        immediate: false,
      },
      durations: {
        period: false,
        date: false,
      },
    },
    changePrice: {
      enabled: true,
      schedules: {
        immediate: true,
        'end-of-period': false,
      },
      prorate: false,
    },
    applyCoupon: {
      enabled: false,
    },
    extendTrial: {
      enabled: false,
    },
  },
  controllers: {
    coupons: {
      enabled: false,
      create: false,
      import: false,
    },
    customers: {
      findByEmail: false,
    },
  },
  features: {
    metricsDashboard: false,
    customerProfile: true,
  },
};
