import { ProviderFeatures } from './provider-features';

// Default provider features with everything disabled
export const defaultProviderFeatures: ProviderFeatures = {
  actions: {
    cancel: {
      enabled: true,
      schedules: {
        immediate: false,
        'end-of-period': true,
      },
      unpauseOnCancel: false,
    },
    pause: {
      enabled: true,
      extendPeriodEnd: false,
      startDates: {
        'period-end': false,
        immediate: true,
      },
      durations: {
        period: true,
        date: false,
      },
    },
    changePrice: {
      enabled: true,
      schedules: {
        immediate: true,
        'end-of-period': false,
      },
      prorate: true,
    },
    applyCoupon: {
      enabled: true,
    },
    extendTrial: {
      enabled: true,
    },
  },
  controllers: {
    coupons: {
      enabled: true,
      create: false,
      import: false,
    },
    customers: {
      findByEmail: false,
    },
  },
  features: {
    metricsDashboard: false,
    customerProfile: false,
  },
};
