import { labels as braintreeProviderLabels } from './braintree';
import { labels as chargebeeProviderLabels } from './chargebee';
import { labels as paddleClassicProviderLabels } from './paddle-classic';
import { labels as paddleBillingProviderLabels } from './paddle-billing';
import { labels as stripeProviderLabels } from './stripe';
import { labels as maxioProviderLabels } from './maxio';
import { labels as defaultProviderLabels } from './default';
import { Provider } from '../types';

export const providerLabels = {
  [Provider.Stripe]: stripeProviderLabels,
  [Provider.Braintree]: braintreeProviderLabels,
  [Provider.ChargebeeV1]: chargebeeProviderLabels,
  [Provider.ChargebeeV2]: chargebeeProviderLabels,
  [Provider.PaddleClassic]: paddleClassicProviderLabels,
  [Provider.PaddleBilling]: paddleBillingProviderLabels,
  [Provider.Maxio]: maxioProviderLabels,
  [Provider.Other]: defaultProviderLabels,
} as const;
