import { ProviderFeatures } from './provider-features';

export const stripeProviderFeatures: ProviderFeatures = {
  actions: {
    cancel: {
      enabled: true,
      schedules: {
        immediate: true,
        'end-of-period': true,
      },
      unpauseOnCancel: true,
    },
    pause: {
      enabled: true,
      extendPeriodEnd: false,
      startDates: {
        // Technically Stripe does not support pausing at the end of the period
        // But we mock this behavior with our Pause Wall and metadata
        'period-end': true,
        immediate: true,
      },
      durations: {
        period: true,
        date: true,
      },
    },
    changePrice: {
      enabled: true,
      schedules: {
        immediate: true,
        'end-of-period': true,
      },
      prorate: true,
    },
    applyCoupon: {
      enabled: true,
    },
    extendTrial: {
      enabled: true,
    },
  },
  controllers: {
    coupons: {
      enabled: true,
      create: true,
      import: true,
    },
    customers: {
      findByEmail: true,
    },
  },
  features: {
    metricsDashboard: true,
    customerProfile: true,
  },
};
