import { braintreeProviderFeatures } from './braintree';
import { chargebeeProviderFeatures } from './chargebee';
import { paddleClassicProviderFeatures } from './paddle-classic';
import { paddleBillingProviderFeatures } from './paddle-billing';
import { stripeProviderFeatures } from './stripe';
import { maxioProviderFeatures } from './maxio';
import { defaultProviderFeatures } from './default';
import { Provider } from '../../types';

export const providerFeatures = {
  [Provider.Stripe]: stripeProviderFeatures,
  [Provider.Braintree]: braintreeProviderFeatures,
  [Provider.ChargebeeV1]: chargebeeProviderFeatures,
  [Provider.ChargebeeV2]: chargebeeProviderFeatures,
  [Provider.PaddleClassic]: paddleClassicProviderFeatures,
  [Provider.PaddleBilling]: paddleBillingProviderFeatures,
  [Provider.Maxio]: maxioProviderFeatures,
  [Provider.Other]: defaultProviderFeatures,
} as const;
