import { ProviderFeatures } from './provider-features';

const pauseCouponId = 'CHURNKEY_PAUSE';

export const braintreeProviderFeatures: ProviderFeatures = {
  actions: {
    cancel: {
      enabled: true,
      schedules: {
        immediate: true,
        'end-of-period': true,
      },
      unpauseOnCancel: false,
    },
    pause: {
      enabled: true,
      extendPeriodEnd: false,
      // Braintree requires a specific coupon ID for pause functionality
      requiredCoupon: pauseCouponId,
      startDates: {
        'period-end': false,
        immediate: true,
      },
      durations: {
        period: true,
        date: false,
      },
    },
    changePrice: {
      enabled: true,
      schedules: {
        immediate: true,
        'end-of-period': false,
      },
      prorate: false,
    },
    applyCoupon: {
      enabled: true,
    },
    extendTrial: {
      enabled: false,
    },
  },
  controllers: {
    coupons: {
      enabled: true,
      create: false,
      import: true,
    },
    customers: {
      findByEmail: true,
    },
  },
  features: {
    metricsDashboard: false,
    customerProfile: false,
  },
};
