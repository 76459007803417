import { ProviderLabels } from './provider-labels';

export const labels: ProviderLabels = {
  provider: 'Chargebee',
  environment: {
    live: 'Live',
    test: 'Test',
  },
  coupon: {
    singular: 'Coupon',
    plural: 'Coupons',
    id: 'ID',
  },
  dashboard: {
    login: 'https://app.chargebee.com/login',
  },
};
