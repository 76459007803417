import { ProviderLabels } from './provider-labels';

export const labels: ProviderLabels = {
  provider: 'Paddle Billing',
  environment: {
    live: 'Live',
    test: 'Sandbox',
  },
  coupon: {
    singular: 'Discount',
    plural: 'Discounts',
    id: 'ID',
  },
  dashboard: {
    login: 'https://login.paddle.com/login',
  },
};
